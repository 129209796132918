<template>
  <div id="home">
    <div class="top-nav-box">
      <div class="top-nav">
        <img src="../assets/img/benq_logo.png" alt="">
        <div class="link-list">
          <a href="https://designvue.benq.com.cn/2021">2021“风潮由我”明基设计大赛</a>
          <a href="https://designvue.benq.com.cn/2021/show">2022“风潮由我”CG数字艺术展</a>
          <a href="https://3.cn/109W-0uSn">明基PD系列专业设计显示器</a>
        </div>
        <div v-if="!$store.state.hasLogin" class="btn-list">
          <div @click="login">注册</div>
          <div @click="login">登录</div>
        </div>
        <div v-else class="btn-list profile-btn">
          <div class="headimg" @click="goProfile" :style="{ 'background-image': `url(${profileinfo.headImg})` }"></div>
          <div class="username" @click="goProfile">{{ profileinfo.userName }}</div>
          <div class="logout" @click="logOut">退出登录</div>
        </div>
      </div>
    </div>
    <div class="banner-box" @click="goReward" v-if="$router.currentRoute.value.href !== '/show'">
    </div>
    <div class="nav-menu-box" v-if="$router.currentRoute.value.href !== '/show'">
      <ul class="nav-menu-List">
        <router-link :to="item.path" v-text="item.meta.title" class="menu-item"
          :class="{ 'router-link-active': item.path == menuPath }" v-for="(item, index) in navMenu"
          :key="index"></router-link>
      </ul>
    </div>
    <div class="container-box">
      <div class="conatiner">
        <router-view />
      </div>
    </div>
    <div class='footer-box'>
      <div class="orgLink">
        <div class="link-wrap">
          <div class="link-item" v-for="(item, index) in logoList.list" :key="index">
            <div class="link-item-name">{{ item.title }}</div>
            <div class="link">
              <a v-for="(item1, index1) in item.data" :key="index1" @click.stop="goLink(item1)">
                <img :src="item1.logoSrc" alt="" :style="{ height: item1.height }" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <beian />
    </div>
  </div>
</template>

<script>
import Beian from '../components/beian.vue'
import { brandHit } from "../utils/apis/brandApi";
import { reactive, watch, ref } from "vue";
import menuList from "../router/menuList";
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex'
import { getPersonal } from '../utils/apis/userApi'
import { verify } from '../utils/apis/userLoginApi';
import { markView, addView } from '../utils/apis/userApi'

export default {
  name: "Home",
  components: { Beian },
  setup() {
    const logoList = reactive({
      list: [
        {
          title: "主 办 方",
          data: [
            {
              name: "明基",
              logoSrc: require("../assets/img/infoPic/BenQ.png"),
              href: "https://s.tb.cn/c.0uNQBj",
              height: "24px",
            },
          ],
        },
        {
          title: "指导单位",
          data: [
            {
              name: "中国电影美术学会CG艺术专业委员会",
              logoSrc: require("../assets/img/infoPic/CCAC.png"),
              href: "http://www.cfada.cn",
              height: "46px",
            }
          ]
        },
        {
          title: "协 办 方",
          data: [
            {
              name: "CG模型网",
              logoSrc: require("../assets/img/infoPic/moxing.png"),
              href: "https://www.cgmodel.com",
              height: "33px",
            },
            {
              name: "GGAC",
              logoSrc: require("../assets/img/infoPic/GGAC.png"),
              href: "https://www.ggac.com/v2/home",
              height: "43px",
            },
            {
              name: "涂鸦王国",
              logoSrc: require("../assets/img/infoPic/tuya.png"),
              href: "https://www.gracg.com/",
              height: "22px",
            },
          ],
        },
        {
          title: "联合品牌",
          data: [
            {
              name: "CBS",
              logoSrc: require("../assets/img/infoPic/CBS.png"),
              href: "https://mall.jd.com/view_page-257315110.html",
              height: "37px",
            },
            {
              name: "Pantone",
              logoSrc: require("../assets/img/infoPic/PANTONE.png"),
              href: "https://www.pantonecn.com/",
              height: "19px",
            },
            {
              name: "Rode",
              logoSrc: require("../assets/img/infoPic/RODE.png"),
              href: "https://mall.jd.com/index-193662.html?from=pc",
              height: "40px",
            }
          ],
        },
        {
          title: "支持单位",
          data: [
            {
              name: "MXDIA",
              logoSrc: require("../assets/img/infoPic/MXDIA.png"),
              href: "https://www.mxdia.com",
              height: "19px",
            },
            {
              name: "玩动画",
              logoSrc: require("../assets/img/infoPic/wandonghua.png"),
              href: "https://weibo.com/u/5756423046",
              height: "45px",
            },
            {
              name: "猹鱼工作室",
              logoSrc: require("../assets/img/infoPic/chayu.png"),
              href: "https://weibo.com/u/5819651289",
              height: "31px",
            },
            {
              name: "动画手册",
              logoSrc: require("../assets/img/infoPic/shouce.png"),
              href: "#",
              height: "36px",
            },
            {
              name: "你好大海品牌设计",
              logoSrc: require("../assets/img/infoPic/HELLOCEAN.png"),
              href: "https://weibo.com/u/1050444554",
              height: "29px",
            },
          ],
        },
        {
          title: "字体支持",
          data: [
            {
              name: "造字工房",
              logoSrc: require("../assets/img/infoPic/zaozi.png"),
              href: "http://makefont.com",
              height: "41px",
            },
          ],
        },
        {
          title: "渲染支持",
          data: [
            {
              name: "炫云",
              logoSrc: require("../assets/img/infoPic/xuanyun.png"),
              href: "https://cloud.shinewonder.com/?aaa=mjsjds",
              height: "33px",
            },
          ],
        },
      ],
    });

    function goLink(item) {
      console.log(item);
      brandHit({ brand: item.name }).then((res) => {
        if (res.data.code === 200) {
          window.location.href = item.href;
        }
      });
    }
    function goUpload() {
      router.push('/uploadFile')
      document.documentElement.scrollTop = 820;
    }
    const store = useStore();
    const navMenu = reactive(menuList);
    const route = useRoute();
    const router = useRouter();
    const menuPath = ref(route.meta.menuPath);
    const profileinfo = reactive({
      userName: '',
      headImg: '',
    })
    markView().then(res => {
      if (res.data.code === 200) {
        setTimeout(() => {
          addView({
            code: res.data.data
          }).then(() => {

          })
        }, 5000)
      }
    })
    if (localStorage.userInfo && localStorage.token) {
      verifyToken()
    }
    // watch 监听
    watch(
      () => route.meta,
      (val, oldval) => {
        console.log(oldval, val);
        menuPath.value = val.menuPath;
      }
    );
    function getPersonlinfo() {
      getPersonal().then(res => {
        if (res.data.code === 200) {
          profileinfo.headImg = res.data.data.headImg
          profileinfo.userName = res.data.data.userName
        }
      })
    }
    function verifyToken() {
      verify().then(res => {
        console.log(res)
        if (res.data.code === 200) {
          getPersonlinfo()
        } else {
          localStorage.removeItem('token')
          localStorage.removeItem('userInfo')
        }
      })
    }
    function goReward() {
      router.push({
        name:"designReward"
      })
    }
    return {
      navMenu,
      menuPath,
      profileinfo,
      logoList,
      goLink,
      goUpload,
      goReward,
    };
  },
  methods: {
    rewardClick() {
    },
    login() {
      this.$router.push('/signUp')
      document.documentElement.scrollTop = 820;
    },
    goProfile() {
      this.$router.push('/myWorks')
      document.documentElement.scrollTop = 820;
    },
    logOut() {
      localStorage.removeItem('token')
      location.reload()
    }
  }
  ,
  mounted() {
  }
};

</script>

<style lang="less">
#home {
  overflow: hidden;
  .uploadLink {
    position: absolute;
    left: 18.5%;
    bottom: 23%;
    width: 13vw;
    height: 4vw;
    min-width: 225px;
    min-height: 65px;
    font-weight: bold;
    opacity: 0;
  }

  min-width: 1200px;
  min-height: 800px;

  .home-title-box {
    height: 60px;
    background-color: #000;

    .home-title-logo {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      img {
        height: 25px;
      }
    }
  }

  .top-nav-box {
    height: 68px;
    background-color: #282828;

    .top-nav {
      max-width: 1600px;
      min-width: 1200px;
      height: 68px;
      margin: 0 auto;
      position: relative;

      &>img {
        width: 158px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .link-list {
        width: 764px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: space-between;

        &>a {
          width: 224px;
          height: 30px;
          border-radius: 6px;
          text-decoration: none;
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 30px;
          cursor: pointer;
        }
      }

      .btn-list {
        margin-top: 16px;
        height: 30px;
        float: right;
        display: flex;
        justify-content: space-around;

        &>div {
          margin-right: 20px;
          height: 40px;
          border-radius: 40px;
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;

          line-height: 40px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

      }

      .profile-btn {
        width: auto;

        .headimg {
          margin: 0;
          width: 40px;
        }

        .username {
          margin-left: 10px;
          margin-right: 20px;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: nowrap;
        }
      }
    }

  }

  .banner-box {
    height: 42.7vw;
    cursor: pointer;
    background: url("../assets/img/banner.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;

    >img {
      height: 4.744vw;
      position: absolute;
      left: 19%;
      bottom: 21%;
    }

    // min-height: 820px;
  }

  .nav-menu-box {
    height: 94px;
    background-color: #001C4D;

    .nav-menu-List {
      width: 1400px;
      margin: 0 auto;
      height: 94px;
      line-height: 94px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-item {
        height: 94px;
        max-width: 280px;
        font-size: 30px;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        color: #dde5f0;
        line-height: 94px;
        letter-spacing: 0.6px;
        flex: 1;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
      }

      .router-link-active {
        background: linear-gradient(0deg, #9bc4fc 0%, #bee9fa 100%);
        color: #001C4D;
      }

      .menu-item:hover {
        background: linear-gradient(0deg, #9bc4fc 0%, #bee9fa 100%);
        color: #001C4D;
      }
    }
  }

  // .container-box {
  //   padding-top: 70px;
  // }
  .conatiner {
    // min-height:400px;
    // width: 1200px;
    box-sizing: border-box;

    // background-image: ;
    // margin: 0 auto;
  }

  .footer-box {
    // height: 330px;
    box-sizing: border-box;

    .orgLink {
      background-color: #272727;
      padding-top: 60px;
      padding-bottom: 55px;

      :nth-child(5) {
        .link-item-name {
          transform: translateY(6px);
        }
      }

      .link-wrap {
        width: 864px;
        height: 411px;
        margin: 0 auto;

        .link-item {
          overflow: hidden;
          margin: 0 auto;
          margin-bottom: 15px;

          .link-item-name {
            float: left;
            line-height: 45px;
            width: 82px;
            font-family: "FZLTHJW";
            font-size: 19px;
            color: #fff;
            text-align-last: justify;
            text-align: justify;
          }

          .link {
            margin-left: 132px;
            line-height: 45px;
            font-size: 0px;

            a {
              display: inline-block;
              line-height: 45px;
              margin-right: 62px;
              cursor: pointer;

              img {
                vertical-align: middle;
              }
            }
          }

          .link :last-child {
            margin: 0;
          }
        }
      }
    }

    .beian {
      // height: 174px;
      // line-height: 174px;
      text-align: center;
      color: #7e3e34;
      padding-top: 20px;

      .beian-box {
        padding: 12px 0 13px;
        text-align: center;

        div {
          display: inline-block;
          font-size: 12px;
          color: rgb(152, 152, 152);

          a {
            text-decoration: none;
            color: rgb(152, 152, 152);
          }

          img {
            height: 12px;
            width: 12px;
            vertical-align: middle;
            // margin-top: 3px;
            margin-right: 6px;
          }
        }

        .line {
          vertical-align: middle;
          background: rgb(152, 152, 152);
          height: 12px;
          font-size: 12px;
          margin: 0 10px;
          width: 1px;
        }
      }
    }
  }
}
</style>
