<template>
  <div class="beian">
    <div class="beian-box">
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          <span>沪ICP备17047194号-5</span>
        </a>
      </div>
      <div class="line"></div>
      <div>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006993" target="_blank">
          <img src="../assets/img/ga_icon.png" alt="" />
          <span>沪公网安备31010502006993号</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'beian'
}
</script>

<style lang='less' scoped>
.beian {
  text-align: center;
  color: #7e3e34;
  padding-top: 20px;

  .beian-box {
    padding: 12px 0 13px;
    text-align: center;

    div {
      display: inline-block;
      font-size: 12px;
      color: rgb(152, 152, 152);

      a {
        text-decoration: none;
        color: rgb(152, 152, 152);
      }

      img {
        height: 12px;
        width: 12px;
        vertical-align: top;
        margin-top: 3px;
        margin-right: 6px;
      }
    }

    .line {
      vertical-align: middle;
      background: rgb(152, 152, 152);
      height: 12px;
      font-size: 12px;
      margin: 0 10px;
      width: 1px;
    }
  }
}
</style>