import request from "../request.js";

//发送验证码
export function sendVerificationCode(params) {
  return request({
    url: "/user/sendVerificationCode",
    method: "POST",
    params,
  });
}

//验证码登录
export function loginByCode(params) {
  return request({
    url: "/user/loginByCode",
    method: "POST",
    data: params,
  });
}

///user/registerByCode
export function registerByCode(params) {
  return request({
    url: "/user/registerByCode",
    method: "POST",
    data: params,
  });
}

export function info() {
  return request({
    url: "/user/info",
    method: "GET",
  });
}

export function verify() {
  return request({
    url: "/token/validToken",
    method: "POST"
  })
}
